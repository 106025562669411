import React, { useCallback } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { buildingStatusSection } from './panelUtil';

import styles from './AlarmListPanel.module.css';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  BuildingStatus,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { AlarmListView } from 'ecto-common/lib/Alarms/V2/AlarmViewV2';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';

const DEFAULT_PAGE_SIZE = 15;
const MIN_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = 50;

type AlarmListPanelConfig = {
  node?: NodeV2ResponseModel;
  pageSize: number;
  buildingStatus?: BuildingStatus[];
};

type AlarmListPanelProps = CustomPanelProps & {
  data: AlarmListPanelConfig;
};

const AlarmListPanel = ({ data }: AlarmListPanelProps) => {
  const node = data?.node;
  const nodeId = node?.nodeId;

  const [sort, setSort] = React.useState<{
    orderBy?: string;
    sortDirection?: SortDirectionType;
  }>({ orderBy: null, sortDirection: null });

  const onSortChanged = useCallback(
    (orderBy: string, sortDirection: SortDirectionType) => {
      setSort({ orderBy, sortDirection });
    },
    []
  );

  if (!node) {
    return <div />;
  }
  return (
    <div className={styles.alarmContainer}>
      <AlarmListView
        nodeId={nodeId}
        searchFilter={''}
        onSortChange={onSortChanged}
        orderBy={sort.orderBy}
        sortDirection={sort.sortDirection}
      />
    </div>
  );
};

const sections: ModelFormSectionType<AlarmListPanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.alarm,
    lines: [
      {
        models: [
          {
            key: (input) => input.pageSize,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.alarmlist.pagesize,
            placeholder: _.toString(DEFAULT_PAGE_SIZE),
            hasError: (value: number) =>
              value !== undefined &&
              !_.inRange(value, MIN_PAGE_SIZE, MAX_PAGE_SIZE + 1)
          }
        ]
      }
    ]
  },
  buildingStatusSection
];

export const AlarmListPanelData = {
  minWidth: 550,
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_list,
  sections,
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  }
};

export default React.memo(AlarmListPanel);
