import React, { useMemo } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import {
  GetAlarmUrlFunction,
  GetSignalsUrlFunction
} from 'ecto-common/lib/hooks/UrlContext';
import { AlarmModel, NodePathItem } from 'ecto-common/lib/API/AlarmsAPIGen';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import AlarmDetailsColumn from './AlarmDetailsColumn';
import ActiveAlarmColumn from './ActiveAlarmColumn';
import StartDateColumn from './StartDateColumn';
import AcknowledgedAtText from './AcknowledgedAtText';
import AcknowledgeButtonV2 from './AcknowledgeButtonV2';
import _ from 'lodash';
import SeverityColumn from './SeverityColumn';
import Flex, { AlignItems, FlexItem } from 'ecto-common/lib/Layout/Flex';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';

export const alarmDetailModels = ({
  tenantId,
  node,
  getAlarmUrl,
  getSignalsUrl,
  showAlarmHistory
}: {
  tenantId: string;
  node: NodeV2ResponseModel;
  getAlarmUrl: GetAlarmUrlFunction;
  getSignalsUrl: GetSignalsUrlFunction;
  showAlarmHistory: (sourceUri: string, occuredAt?: string) => void;
}): DataTableColumnProps<AlarmModel>[] => [
  {
    dataKey: 'firstActiveAtSinceLastAcknowledgement',
    label: T.alarms.columns.firstactivedate,
    dataFormatter: (alarmDate: string, alarm: AlarmModel) => (
      <StartDateColumn
        alarm={alarm}
        alarmDate={alarmDate}
        showAlarmHistory={showAlarmHistory}
      />
    )
  },
  {
    dataKey: 'severity',
    label: T.alarms.columns.severity,
    dataFormatter: (severity: number) => {
      return (
        <Flex
          direction={LayoutDirection.HORIZONTAL}
          alignItems={AlignItems.CENTER}
        >
          <FlexItem>
            <SeverityColumn severity={severity} />
          </FlexItem>
          <FlexItem>{severity}</FlexItem>
        </Flex>
      );
    }
  },
  {
    dataKey: 'properties.signalName',
    label: T.alarms.columns.signal
  },
  {
    dataKey: 'properties.signalDescription',
    label: T.admin.equipmentsignal.description
  },
  {
    dataKey: 'nodePath',
    label: T.alarms.columns.equipment,
    dataFormatter: (nodePath: NodePathItem[]) => {
      return (
        <AlarmDetailsColumn
          tenantId={tenantId}
          nodePath={nodePath}
          getAlarmUrl={getAlarmUrl}
          getSignalsUrl={getSignalsUrl}
        />
      );
    }
  },
  {
    dataKey: 'isActive',
    label: T.alarms.columns.status,
    dataFormatter: (_unused: boolean, alarm: AlarmModel) => (
      <ActiveAlarmColumn alarm={alarm} showAlarmHistory={showAlarmHistory} />
    )
  },
  {
    dataKey: 'acknowledgedAt',
    label: T.alarms.columns.acknowledge,
    dataFormatter: (acknowledgedAt: string, alarm: AlarmModel) => {
      const isAcknowledged = acknowledgedAt != null;

      if (isAcknowledged) {
        return (
          <AcknowledgedAtText alarm={alarm} isAcknowledged={isAcknowledged} />
        );
      }

      return <AcknowledgeButtonV2 node={node} alarm={alarm} />;
    }
  },
  {
    dataKey: 'acknowledgedComment',
    label: T.alarms.acknowledge.comment
  },
  {
    dataKey: 'message',
    label: T.common.message
  }
];

type NameValue = {
  name: string | React.ReactNode;
  value: string | React.ReactNode;
};

const columns: DataTableColumnProps<NameValue>[] = [
  {
    label: T.common.name,
    dataKey: 'name',
    flexGrow: 1
  },
  {
    label: T.equipment.value,
    dataKey: 'value',
    flexGrow: 6
  }
];

const AlarmDetailsModal = ({
  alarm,
  node,
  onModalClose,
  tenantId,
  getAlarmUrl,
  getSignalsUrl,
  showAlarmHistory
}: {
  alarm?: AlarmModel;
  node: NodeV2ResponseModel;
  onModalClose: () => void;
  tenantId: string;
  getAlarmUrl: GetAlarmUrlFunction;
  getSignalsUrl: GetSignalsUrlFunction;
  showAlarmHistory: (sourceUri: string, occuredAt?: string) => void;
}) => {
  const items = useMemo(() => {
    return alarmDetailModels({
      tenantId,
      node,
      getAlarmUrl,
      getSignalsUrl,
      showAlarmHistory
    }).map((column, index) => ({
      name: column.label,
      value: column.dataFormatter
        ? column.dataFormatter(
            _.get(alarm, column.dataKey),
            alarm,
            index,
            false,
            column
          )
        : _.get(alarm, column.dataKey) || '-'
    }));
  }, [alarm, getAlarmUrl, getSignalsUrl, node, showAlarmHistory, tenantId]);
  return (
    <ActionModal
      isOpen={alarm != null}
      onModalClose={onModalClose}
      disableCancel
      headerIcon={Icons.InfoCircle}
      title={T.common.details}
      onConfirmClick={onModalClose}
      actionText={T.common.done}
      wide
    >
      <DataTable<NameValue> data={items} columns={columns} />
    </ActionModal>
  );
};
export default AlarmDetailsModal;
