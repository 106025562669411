import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  SetStateAction,
  Dispatch
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { KEY_CODE_ENTER } from 'ecto-common/lib/constants';
import styles from './LocationTreeView.module.css';
import LocationTreeViewRow, {
  getTreeRowsForNodes,
  LocationTreeViewNodeWithChildren
} from './LocationTreeViewRow';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { centerListEntry } from 'ecto-common/lib/utils/scrollUtils';

export type LocationTreeViewNode = {
  name: string;
  nodeId: string;
  parentId?: string;
};

type LocationTreeViewProps = {
  /**
   * A flat list of nodes.
   */
  nodeList: LocationTreeViewNode[];
  /**
   * Used to override the appearance of the tree view container. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * A list of node ID:s for all of the nodes that are selected. If not using multiSelect this should only
   * contain one element.
   */
  selectedIds?: string[];
  /**
   * Called whenever the user has clicked on a node. Arguments are (nodeId, isSelected).
   */
  onChangeSelectedState?(parentId: string, isSelected: boolean): void;
  /**
   * If set to true then it is possible to select multiple nodes. A checkbox will appear to the left of the node item.
   */
  multiSelect?: boolean;

  /**
   * This property allows you to specify that the tree should make sure that the node with this ID is visible (i.e. the scrolling will be adjusted if necessary). Should always be set
   * together with focusedGrid. This ID should be included in selectedIds.
   */
  focusedId?: string;
  /**
   * If using multi select, filter which items are actually possible to select. If you only want to select buildings for instance you can filter on node type. Called repeatedly for each node with the node as the only argument.
   */
  multiSelectFilter?(node: LocationTreeViewNodeWithChildren): boolean;

  /**
   * Allows you to render extra icons right next to the label. This is useful for instance if you want to show a warning icon if the equipment is in an error state.
   */
  renderRowIcons?: (node: LocationTreeViewNodeWithChildren) => React.ReactNode;

  /**
   * Allows you to render extra icons to the far right of the row.
   */
  renderRowSideIcons?: (
    node: LocationTreeViewNodeWithChildren
  ) => React.ReactNode;

  expanded?: Record<string, boolean>;
  setExpanded?: Dispatch<SetStateAction<Record<string, boolean>>>;

  onExpandedStateChange?: (nodeId: string, expanded: boolean) => void;

  nodeHasChildren?: (nodeId: LocationTreeViewNodeWithChildren) => boolean;

  style?: React.CSSProperties;
};

/**
 * A location tree view is a tree navigation component for node hierarchies (sites, buildings equipments).
 *
 * The design is similar to many filesystem browsers. You can expand and contract nodes which contain child nodes.
 *
 * In the future we might make this component more generic and support more use cases. We will also move some of the navigation logic out of this class.
 */
function LocationTreeView({
  nodeList,
  className,
  selectedIds = [],
  onChangeSelectedState,
  multiSelect = false,
  focusedId = null,
  multiSelectFilter,
  renderRowIcons,
  renderRowSideIcons,
  expanded,
  onExpandedStateChange,
  setExpanded,
  nodeHasChildren,
  style = undefined
}: LocationTreeViewProps) {
  const expandAll = expanded == null;
  const centerEntryRef = useRef(null);

  const expandPath = useCallback(
    (nodeId: string, toggle: boolean) => {
      let newValue = true;
      if (toggle) {
        newValue = !expanded?.[nodeId];
      }

      onExpandedStateChange?.(nodeId, newValue);
      const newExpanded = { ...expanded, [nodeId]: newValue };
      setExpanded?.(newExpanded);
    },
    [expanded, onExpandedStateChange, setExpanded]
  );

  const selectLocation = useCallback(
    (location: LocationTreeViewNodeWithChildren, isSelected: boolean) => {
      expandPath(location.nodeId, true);

      if (multiSelect && multiSelectFilter && !multiSelectFilter(location)) {
        return;
      }

      onChangeSelectedState(location.nodeId, !isSelected);
    },
    [expandPath, multiSelect, multiSelectFilter, onChangeSelectedState]
  );

  useEffect(() => {
    const currentNode = nodeList.find((node) => node.nodeId === focusedId);

    if (currentNode != null && expanded[currentNode.nodeId] == null) {
      setExpanded?.((oldExpanded) => {
        const newExpanded = { ...oldExpanded };
        newExpanded[currentNode.nodeId] = true;

        let parentId = currentNode.parentId;
        while (parentId != null) {
          newExpanded[parentId] = true;
          parentId = nodeList.find(
            (node) => node.nodeId === parentId
          )?.parentId;
        }

        return newExpanded;
      });
    }
  }, [expanded, focusedId, nodeList, setExpanded]);

  const onKeyUp = useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement>,
      node: LocationTreeViewNodeWithChildren
    ) => {
      if (event.keyCode === KEY_CODE_ENTER) {
        selectLocation(node, false);
      }
    },
    [selectLocation]
  );

  const treeRows = useMemo(() => {
    const nodesAndChildren: Record<string, LocationTreeViewNodeWithChildren> =
      {};

    for (const node of nodeList) {
      nodesAndChildren[node.nodeId] = {
        ...node,
        children: []
      };
    }

    const missingParents: Record<string, boolean> = {};

    for (const node of Object.values(nodesAndChildren)) {
      if (node.parentId) {
        const parent = nodesAndChildren[node.parentId];

        if (parent) {
          parent.children.push(node);
        } else {
          missingParents[node.parentId] = true;
        }
      }
    }

    for (const node of Object.values(nodesAndChildren)) {
      node.children = sortByLocaleCompare(node.children, 'name');
    }

    const topLevelNodes = Object.values(nodesAndChildren).filter(
      (node) => !node.parentId || missingParents[node.nodeId]
    );

    const _treeRows = getTreeRowsForNodes(
      topLevelNodes,
      '',
      null,
      true,
      expandAll ? null : expanded
    );

    // If we only have one root node, we don't want the left-most column of padding.
    // Somewhat ugly workaround, just remove the first blank prefix cell
    // after tree has been created (keeps tree generation algorithm simpler)
    if (topLevelNodes.length === 1) {
      _treeRows.forEach((locationRow) => {
        if (_.startsWith(locationRow.prefix, ' ')) {
          locationRow.prefix = locationRow.prefix.substring(1);
        }
      });
    }

    return _treeRows;
  }, [nodeList, expandAll, expanded]);

  const lastCenteredId = useRef<string | null>();

  // Make sure the search result is visible on screen after clearing search
  useEffect(() => {
    if (focusedId && focusedId !== lastCenteredId.current) {
      if (centerListEntry(focusedId, centerEntryRef.current)) {
        lastCenteredId.current = focusedId;
      }
    }
  }, [focusedId, treeRows]);

  const locationRows = treeRows;
  let isEvenRow = true;

  const treeNodes = locationRows.map((row) => {
    const ret = (
      <LocationTreeViewRow
        node={row.node}
        parentNode={row.parentNode}
        prefix={row.prefix}
        key={row.node.nodeId}
        expandAll={expandAll}
        expandPath={expandPath}
        expanded={expandAll || expanded?.[row.node.nodeId]}
        multiSelect={multiSelect}
        multiSelectFilter={multiSelectFilter}
        onChangeSelectedState={onChangeSelectedState}
        onKeyUp={onKeyUp}
        selectLocation={selectLocation}
        selected={selectedIds.includes(row.node.nodeId)}
        isEvenRow={isEvenRow}
        renderRowIcons={renderRowIcons}
        renderRowSideIcons={renderRowSideIcons}
        hasChildren={
          nodeHasChildren
            ? nodeHasChildren(row.node)
            : row.node.children.length > 0
        }
      />
    );

    isEvenRow = !isEvenRow;

    return ret;
  });

  return (
    <div
      className={classNames(styles.treeView, className)}
      ref={centerEntryRef}
      style={style}
    >
      <div key="root-locations" className={classNames(styles.locationPicker)}>
        {treeNodes}
      </div>
    </div>
  );
}

export default React.memo(LocationTreeView);
