import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import styles from './SelectPresentationItemDialog.module.css';
import {
  AssignItemPromise,
  DeleteItemPromise,
  GetItemHookType,
  ListItemsHook,
  sortPriority,
  useListQuery,
  usePresentationItemQuery,
  useUpdatePresentationItemMutation
} from '../utils/presentationLibrary';
import ItemPrioritySelector from '../ItemPrioritySelector/ItemPrioritySelector';
import TenantContext from '../hooks/TenantContext';
import { TEMPLATE_ADMIN_ID } from '../constants';

export type Item = {
  id?: string;
  name?: string;
  priority?: number;
  nodeId?: string;
};

const SelectPresentationItemDialog = ({
  title,
  isOpen,
  onModalClose,
  nodeId,
  nodeType,
  initialSelectedItems = null,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save,
  listItemsHook,
  getItemHook,
  saveItemPromise,
  deleteItemPromise
}: {
  title: string;
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  nodeType?: string; // used when no specific node id is given
  initialSelectedItems?: Item[];
  equipmentTypeId?: string;
  onConfirm?: (item: Item[]) => void;
  actionText?: React.ReactNode;
  listItemsHook: ListItemsHook;
  getItemHook: GetItemHookType;
  saveItemPromise: AssignItemPromise;
  deleteItemPromise: DeleteItemPromise;
}) => {
  const [hasChanges, setHasChanges] = useState(false);

  const equipmentOrNodeId = nodeId ?? equipmentTypeId;

  const { query: relationQuery, nodeTypes } = usePresentationItemQuery({
    queryHook: getItemHook,
    nodeId,
    nodeType,
    equipmentTypeId,
    options: { enabled: isOpen }
  });

  const [_selectedData, setSelectedData] = useState<Item[]>(null);
  const initialItems = useMemo(() => {
    return sortPriority(
      _.uniqBy(
        _.concat(initialSelectedItems ?? [], relationQuery.data.items),
        'id'
      )
    );
  }, [initialSelectedItems, relationQuery.data.items]);
  const selectedData = _selectedData ?? initialItems;

  const saveMutation = useUpdatePresentationItemMutation(
    saveItemPromise,
    deleteItemPromise,
    equipmentOrNodeId,
    {
      meta: {
        errorString: T.common.error
      },
      onSuccess: () => {
        onModalClose();
      }
    }
  );

  const itemsQuery = useListQuery(listItemsHook, nodeTypes, {
    enabled: isOpen
  });

  useEffect(() => {
    if (isOpen) {
      setSelectedData(null);
      setHasChanges(false);
    }
  }, [isOpen]);

  const onConfirmClick = useCallback(() => {
    if (onConfirm) {
      onConfirm(selectedData);
    } else {
      saveMutation.mutate({
        originalData: relationQuery.data,
        assignedPresentationItems: selectedData
      });
    }
  }, [onConfirm, selectedData, saveMutation, relationQuery.data]);

  const onSelectedDataChanged = useCallback((items: Item[]) => {
    setSelectedData(items);
    setHasChanges(true);
  }, []);

  const { tenantId } = useContext(TenantContext);
  const typeSpecificPriorityOffset =
    tenantId === TEMPLATE_ADMIN_ID ? 1000 : 100;
  const isGlobalEditor = tenantId === TEMPLATE_ADMIN_ID;

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={title}
      onConfirmClick={onConfirmClick}
      actionText={actionText}
      disableActionButton={!hasChanges}
      headerIcon={Icons.Settings}
      isLoading={saveMutation.isPending}
      className={styles.dialog}
    >
      <ItemPrioritySelector
        nodeId={nodeId}
        canOnlyEditUnassignedTypes={nodeType != null}
        items={itemsQuery.data}
        hasError={itemsQuery.isError || relationQuery.isError}
        isLoading={itemsQuery.isLoading || relationQuery.isLoading}
        selectedData={selectedData}
        onSelectedDataChanged={onSelectedDataChanged}
        typeSpecificPriorityOffset={typeSpecificPriorityOffset}
        isGlobalEditor={isGlobalEditor}
      />
    </ActionModal>
  );
};

export default SelectPresentationItemDialog;
