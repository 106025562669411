import _ from 'lodash';

import { getSignalColor } from 'ecto-common/lib/SignalSelector/StockChart.config';
import T from 'ecto-common/lib/lang/Language';
import {
  DEFAULT_TIMEZONE,
  GraphicalRepresentation
} from 'ecto-common/lib/constants';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import moment from 'moment';
import {
  getSignalTypeUnit,
  getSignalTypeUnitObject
} from 'ecto-common/lib/SignalSelector/SignalUtils';
import APIGen, {
  AggregationType,
  DataFormat,
  FullSignalProviderResponseModel,
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  SamplingInterval,
  SignalProviderByNodeResponseModel,
  SignalProviderSignalResponseModel,
  SignalProviderTelemetryResponseModel,
  SignalProviderTelemetryValueResponseModel,
  SignalProviderType,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { AggregationText } from 'ecto-common/lib/types/Aggregation';
import { SamplingIntervalText } from 'ecto-common/lib/types/SamplingInterval';
import UUID from 'uuidjs';
import {
  GraphCollectionType,
  GraphMinMaxSettings,
  GraphSettingsType,
  GraphSettingsTypeDeprecated
} from 'ecto-common/lib/types/EctoCommonTypes';

import { YAxisOptions } from 'highcharts';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import { styleLegendItems } from 'ecto-common/lib/Charts/ChartUtil';
import { nodeIsEquipment } from 'ecto-common/lib/hooks/useCurrentNode';

export const GRAPH_SETTINGS_STORAGE_KEY =
  'operator.graphs.signals.defaultcollections';

const formatDate = (date: number) => {
  return moment
    .utc(date)
    .local()
    .format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME));
};

export type ChartSignalSettingsType = {
  aggregation: AggregationType;
  samplingInterval: SamplingInterval;
};

export type ChartSignal = {
  index?: number;
  chartSignalId: string;
  group: Omit<FullSignalProviderResponseModel, 'signals'>;
  item: SignalProviderSignalResponseModel;
  color?: string;
  parent?: Omit<FullSignalProviderResponseModel, 'signals'>;
  settings?: ChartSignalSettingsType;
};

// Telemetry response with both the values and the data used to request it
export type TelemetryAndAggregationResponseModel =
  SignalProviderTelemetryResponseModel & {
    aggregation: AggregationType;
    samplingInterval: SamplingInterval;
  };

export const getNodeName = (
  signalProvider: FullSignalProviderResponseModel,
  nodes: NodeV2ResponseModel[],
  equipmentParentNodes: (
    | NodeV2ResponseModel
    | NodeParentInformationResponseModel
  )[],
  separator = ' - '
) => {
  let nodeNamePrefix = '';
  const nodeIds = signalProvider.nodeIds;

  if (
    signalProvider.signalProviderType !== SignalProviderType.Meteorology &&
    nodeIds &&
    nodeIds.length > 0
  ) {
    const nodeId = nodeIds[0];

    const node = nodes.find((n) => n.nodeId === nodeId);
    const nodeNameIsEquipment = node && nodeIsEquipment(node);

    if (node) {
      nodeNamePrefix = node.name;
    }

    if (nodeNameIsEquipment) {
      nodeNamePrefix = node.name;

      const parent = equipmentParentNodes.find(
        (p) => p.nodeId === node.parentId
      );

      if (parent) {
        nodeNamePrefix = parent.name + separator + nodeNamePrefix;
      }

      if (signalProvider.signalProviderType !== SignalProviderType.Equipment) {
        nodeNamePrefix += separator + signalProvider.signalProviderName;
      }
    }

    return nodeNamePrefix;
  }

  return signalProvider.signalProviderName;
};

export const getSeriesName = (
  signal: SignalProviderSignalResponseModel,
  signalProvider: SignalProviderByNodeResponseModel,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>,
  nodes: NodeV2ResponseModel[],
  equipmentParentNodes: (
    | NodeParentInformationResponseModel
    | NodeV2ResponseModel
  )[],
  samplingInterval: SamplingInterval = null,
  aggregation: AggregationType = null
) => {
  const unit = getSignalTypeUnit(
    signal.signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  const nodeNamePrefix = getNodeName(
    signalProvider,
    nodes ?? [],
    equipmentParentNodes ?? []
  );
  let name = nodeNamePrefix + ' - ' + signal.name + ' (' + (unit ?? '') + ')';

  if (
    samplingInterval != null &&
    aggregation != null &&
    (samplingInterval !== SamplingInterval.Raw ||
      aggregation !== AggregationType.None)
  ) {
    name +=
      ' - ' +
      SamplingIntervalText[samplingInterval] +
      ', ' +
      AggregationText[aggregation];
  }

  return name;
};

// Workaround for the API returning duplicated data; remove after
// where the dates start to repeat
const _fixBrokenData = (
  signals: SignalProviderTelemetryValueResponseModel[]
) => {
  let splitIndex = signals.length;

  for (let i = 1; i < signals.length; i++) {
    if (
      new Date(signals[i - 1].time).getTime() >
      new Date(signals[i].time).getTime()
    ) {
      splitIndex = i;
      break;
    }
  }

  return signals.slice(0, splitIndex);
};

export type TelemetrySeries = {
  unit: string;
  unitId: string;
  color: string;
  groupName: string;
  signal: SignalProviderSignalResponseModel;
  chartSignalId: string;
  dataFormat: DataFormat;
  name: string;
  data: number[][];
};

export const convertTelemetryToSeries = (
  selectedSignals: ChartSignal[],
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>,
  graphSettings: GraphSettingsType,
  data: TelemetryAndAggregationResponseModel[],
  nodes: NodeV2ResponseModel[],
  parentNodes: NodeParentInformationResponseModel[]
): TelemetrySeries[] => {
  return selectedSignals.map((selectedSignal, idx) => {
    const selectedSignalSettings: ChartSignalSettingsType = {
      aggregation:
        selectedSignal.settings?.aggregation ??
        graphSettings.aggregation ??
        AggregationType.None,
      samplingInterval:
        selectedSignal?.settings?.samplingInterval ??
        graphSettings.samplingInterval ??
        SamplingInterval.Raw
    };

    const seriesEntry: TelemetryAndAggregationResponseModel = _.find(data, {
      signalId: selectedSignal.item.signalId,
      aggregation: selectedSignalSettings.aggregation,
      samplingInterval: selectedSignalSettings.samplingInterval
    });

    const hasData = seriesEntry != null;
    const modSignals = hasData ? _fixBrokenData(seriesEntry.signals) : [];
    const seriesData = modSignals.map(({ time, value }) => [
      new Date(time).getTime(),
      value
    ]);

    const unit = getSignalTypeUnitObject(
      selectedSignal.item.signalTypeId,
      signalTypesMap,
      signalUnitTypesMap
    );
    // In this case, show sampling interval and aggregation from signal as part of name, if it isn't set specifically
    // for the signal, show graph default if it is different from raw / none - it's useful to see the aggregation
    // for a signal even if it uses the chart default as its not shown anywhere else except the settings.
    let name = getSeriesName(
      selectedSignal.item,
      selectedSignal.group,
      signalTypesMap,
      signalUnitTypesMap,
      nodes,
      parentNodes,
      selectedSignalSettings.samplingInterval,
      selectedSignalSettings.aggregation
    );

    if (!hasData) {
      name += ' (' + T.powercontrol.dispatch.chart.nodata + ')';
    }

    return {
      unit: unit.unit || 'n/a',
      unitId: unit.id,
      color: getSignalColor(
        selectedSignal,
        selectedSignal.index == null ? idx : selectedSignal.index
      ),
      groupName: selectedSignal.group.signalProviderName,
      signal: selectedSignal.item,
      chartSignalId: selectedSignal.chartSignalId,
      dataFormat: selectedSignal.item.dataFormat || DataFormat.Continuous,
      data: seriesData,
      name
    };
  });
};

const UNKNOWN_UNIT = T.powercontrol.dispatch.chart.unknownunit;

export const exportChartConfig = {
  enabled: false,
  chartOptions: {
    navigator: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false
        }
      }
    }
  },
  fallbackToExportServer: false
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SettingsByRepresentation: Record<string, any> = {
  [GraphicalRepresentation.EVENT]: {
    typeSettings: {
      type: 'scatter',
      lineWidth: 0,
      marker: {
        radius: 6,
        symbol: 'circle'
      }
    },
    tooltipSettings: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name} - <strong>{point.y}</strong>',
      headerFormat: '{point.key}',
      split: false
    }
  },
  [GraphicalRepresentation.LINEAR]: {
    typeSettings: {},
    tooltipSettings: {}
  },
  [GraphicalRepresentation.SPLINE]: {
    typeSettings: { type: 'spline' },
    tooltipSettings: {}
  },
  [GraphicalRepresentation.STEP]: {
    typeSettings: { step: 'left' },
    tooltipSettings: {}
  }
};

export const chartFormattedNumber = (
  value: number,
  tickInterval: number,
  unit: string,
  numberOfDecimals?: number
) => {
  const tickIntervalStr = tickInterval + '';
  const tickIntervalIndex = tickIntervalStr.indexOf('.');
  let tickIntervalDecimals = 0;

  if (tickIntervalIndex !== -1) {
    tickIntervalDecimals =
      tickIntervalStr.length - 1 - tickIntervalStr.indexOf('.');
  }
  const unitDecimals = numDecimalsForUnit(unit);

  // Add one more to tick interval decimals to see detail between tick steps
  const numDecimals =
    numberOfDecimals != null
      ? numberOfDecimals
      : Math.max(tickIntervalDecimals + 1, unitDecimals);

  return formatNumberUnit(value, unit, numDecimals);
};

// TODO: I don't think this function matches all of its input args
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const yAxisFormatter = (point: any) => {
  return formatNumberUnit(point.value, null);
};

export const getMinOrMaxForAxis = (
  unitId: string,
  minOrMax: 'min' | 'max',
  minMaxSettings: Record<string, GraphMinMaxSettings>
) => {
  return minMaxSettings?.[unitId]?.[minOrMax];
};

export const createPointFormatter = (
  unit: string,
  numberOfDecimals?: number
) => {
  return function () {
    const tickInterval = this.series.yAxis.tickInterval;
    const symbol = '&#9632';
    return (
      '<span style="color:' +
      this.series.color +
      ';">' +
      symbol +
      '</span> ' +
      this.series.name +
      ': <strong>' +
      chartFormattedNumber(this.y, tickInterval, unit, numberOfDecimals) +
      '</strong>'
    );
  };
};

export const createStockChartConfigFromSeries = (
  series: TelemetrySeries[],
  navigatorSeries: TelemetrySeries[],
  minMaxSettings: Record<string, GraphMinMaxSettings> = null
): Highcharts.Options => {
  const yAxis: YAxisOptions[] = _.map(
    _.uniqBy(series, (x) => x.unit ?? UNKNOWN_UNIT),
    (item, idx) => ({
      id: item.unit,
      title: {
        text: item.unit
      },
      min: getMinOrMaxForAxis(item.unitId, 'min', minMaxSettings),
      max: getMinOrMaxForAxis(item.unitId, 'max', minMaxSettings),
      labels: {
        formatter: yAxisFormatter
      },
      showEmpty: true,
      opposite: idx % 2 === 1
    })
  );

  const modifiedSeries = _.map(series, (dataSeries) => {
    const unit = dataSeries.unit || UNKNOWN_UNIT;
    const typeSettings =
      SettingsByRepresentation[dataSeries.signal.graphicalRepresentation]
        .typeSettings;
    const tooltipSettings =
      SettingsByRepresentation[dataSeries.signal.graphicalRepresentation]
        .tooltipSettings;

    return {
      ...dataSeries,
      showInNavigator: false,
      yAxis: unit,
      ...typeSettings,
      tooltip: {
        pointFormatter: createPointFormatter(dataSeries.unit),
        // Don't show unit if its null
        valueSuffix: dataSeries.unit ? ' ' + unit : '',
        ...tooltipSettings
      }
    };
  });

  return {
    yAxis: _.isEmpty(yAxis) ? null : yAxis,
    navigator: {
      adaptToUpdatedData: false,
      height: 80,
      enabled: navigatorSeries != null,
      series: navigatorSeries != null ? navigatorSeries : []
    },
    series: modifiedSeries,
    exporting: exportChartConfig
  };
};

const timeDiff = (timestamp: number, xAxisData: number[][], index: number) =>
  Math.abs(xAxisData[index][0] - timestamp);

// Find closest matching entry in xAxisData based on diff to timestamp.
// Start search at startIndex to reduce time complexity.
const findClosest = (
  timestamp: number,
  xAxisData: number[][],
  startIndex: number
) => {
  let diff = timeDiff(timestamp, xAxisData, startIndex);

  for (let i = startIndex; i < xAxisData.length - 1; i++) {
    const nextDiff = timeDiff(timestamp, xAxisData, i + 1);

    if (nextDiff <= diff) {
      diff = nextDiff;
    } else {
      // Diff to next point is larger than previous, exit.
      return i;
    }
  }

  return xAxisData.length - 1;
};

export const createScatterChartConfigFromSeries = (
  series: TelemetrySeries[],
  _navigatorSeries: TelemetrySeries[],
  xAxisSeries: TelemetrySeries,
  minMaxSettings: Record<string, GraphMinMaxSettings> = null
): Highcharts.Options => {
  const yAxis: YAxisOptions[] = _.map(
    _.uniqBy(_.without(series, xAxisSeries), (x) => x.unit ?? UNKNOWN_UNIT),
    (item, idx) => ({
      id: item.unit,
      title: {
        text: item.unit
      },
      min: getMinOrMaxForAxis(item.unitId, 'min', minMaxSettings),
      max: getMinOrMaxForAxis(item.unitId, 'max', minMaxSettings),
      labels: {
        formatter: yAxisFormatter
      },
      showEmpty: true,
      opposite: idx % 2 === 1
    })
  );

  const xAxisText = xAxisSeries?.name ?? '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let modifiedSeries: any[] = [];

  if (xAxisSeries != null && xAxisSeries.data.length > 0) {
    modifiedSeries = _.map(_.without(series, xAxisSeries), (dataSeries) => {
      let xAxisIndex = 0;

      const newData = _.map(dataSeries.data, (values) => {
        xAxisIndex = findClosest(values[0], xAxisSeries.data, xAxisIndex);
        const xIndexValue = xAxisSeries.data[xAxisIndex];
        return [xIndexValue[1], values[1], xIndexValue[0]];
      });

      return {
        data: newData,
        chartSignalId: dataSeries.chartSignalId,
        name: dataSeries.name,
        unit: dataSeries.unit,
        yAxis: dataSeries.unit,
        color: dataSeries.color,
        tooltip: {
          pointFormatter: function () {
            const timestamp = newData[this.index][2];
            return (
              formatDate(timestamp) +
              '<br/><strong>' +
              chartFormattedNumber(
                this.x,
                this.series.xAxis.tickInterval,
                xAxisSeries?.unit
              ) +
              ', ' +
              chartFormattedNumber(
                this.y,
                this.series.yAxis.tickInterval,
                dataSeries.unit
              ) +
              '</strong>'
            );
          }
        }
      };
    });
  }

  return {
    xAxis: {
      title: {
        text: xAxisText
      },
      min:
        xAxisSeries != null
          ? getMinOrMaxForAxis(xAxisSeries.unitId, 'min', minMaxSettings)
          : null,
      max:
        xAxisSeries != null
          ? getMinOrMaxForAxis(xAxisSeries.unitId, 'max', minMaxSettings)
          : null,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true
    },
    yAxis,
    series: modifiedSeries,
    exporting: exportChartConfig
  };
};

export const migrateGraphCollections = (collections: GraphCollectionType[]) => {
  for (const collection of collections) {
    const deprecatedSettings =
      collection.settings as GraphSettingsTypeDeprecated;

    for (const signal of collection.signals) {
      if (signal.chartSignalId == null) {
        signal.chartSignalId = UUID.generate();
      }
    }

    if (deprecatedSettings?.xAxisSignalId != null) {
      const chartSignal = _.find(collection.signals, [
        'item.signalId',
        deprecatedSettings.xAxisSignalId
      ]);
      if (chartSignal !== null) {
        collection.settings.xAxisChartSignalId = chartSignal.chartSignalId;
      }
      delete deprecatedSettings.xAxisSignalId;
    }
  }

  return collections;
};

/**
 * Previously we used to store the full signal object (name, description etc)
 * along with the signal provider data in the user settings. This was convenient as we did
 * not have to do any additional API calls to show the graph data (with signal names etc).
 * Just load the collections from the user settings and be ready to go.
 *
 * However, name changes and other changes would not get picked up. And when we introduced
 * signal types, none of the old graphs would show units etc as the stored signal objects
 * did not contain the signalTypeId property.
 *
 * This workaround loads the collections first and then loads all of the signal objects
 * for the signals in every collection. It then replaces the previously stored signal and
 * provider data with the newly fetched data. This means that changes in the signal data
 * or structure will always be synced, and we don't have to rewrite the entire graph
 * telemetry reducer. In the future, when we replace that old and complicated piece of code,
 * we might solve it differently.
 */
export const getSignalCollectionsPromise = (
  contextSettings: ApiContextSettings,
  abortSignal: AbortSignal,
  isAdmin: boolean
) => {
  let collections: GraphCollectionType[] = [];
  const getProvidersPromise = isAdmin
    ? APIGen.AdminSignals.getProvidersBySignalIds.promise
    : APIGen.Signals.getProvidersBySignalIds.promise;

  return IdentityServiceAPIGenV2.TenantUser.getUserSettings
    .promise(contextSettings, abortSignal)
    .then((result) => {
      const settings =
        result?.settings && typeof result?.settings === 'string'
          ? JSON.parse(result.settings)
          : {};
      const collectionSettings = _.get(settings, GRAPH_SETTINGS_STORAGE_KEY, {
        collections: []
      });
      collections = collectionSettings.collections;

      const allSignalIds: string[] = _(collections)
        .flatMap((collection) => _.map(collection.signals, 'item.signalId'))
        .uniqBy(_.identity)
        .value();

      if (allSignalIds.length === 0) {
        return Promise.resolve([]);
      }

      return getProvidersPromise(
        contextSettings,
        { signalIds: allSignalIds },
        abortSignal
      );
    })
    .then((providers: FullSignalProviderResponseModel[]) => {
      const signalsWithProviders = _(providers)
        .flatMap((provider) =>
          _.map(provider.signals, (signal) => ({ ...signal, provider }))
        )
        .keyBy('signalId')
        .value();

      _.forEach(collections, (collection) => {
        collection.signals = _.map(
          _.filter(collection.signals, (signal) =>
            Boolean(signalsWithProviders[signal.item.signalId])
          ),
          (signal) => {
            return {
              ...signal,
              item: _.omit(
                signalsWithProviders[signal.item.signalId],
                'provider'
              ),
              group: _.omit(
                signalsWithProviders[signal.item.signalId].provider,
                'signals'
              )
            };
          }
        );
      });

      return sortByLocaleCompare(collections, 'name');
    });
};

export const CHART_BOOST_THRESHOLD = 1000;

export const boostHighchartsSettings = () => {
  return {
    boost: {
      boostThreshold: CHART_BOOST_THRESHOLD,
      turboThreshold: CHART_BOOST_THRESHOLD,
      allowForce: false,
      enabled: true,
      useGPUTranslations: true,
      seriesTreshold: CHART_BOOST_THRESHOLD
    }
  };
};

export function standardStockChartOptions(enableAnimation: boolean) {
  return {
    time: {
      useUTC: false,
      timezone: DEFAULT_TIMEZONE
    },
    ...boostHighchartsSettings(),
    dataGrouping: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: enableAnimation,
        lineWidth: 1.25
      },
      line: {
        states: {
          hover: {
            lineWidth: 1.25
          }
        },
        dataGrouping: {
          enabled: false
        }
      }
    },
    rangeSelector: {
      enabled: false,
      inputEnabled: false
    },
    exporting: {
      enabled: false
    },
    xAxis: {
      id: 'xAxis',
      ordinal: false
    },
    scrollbar: {
      liveRedraw: false,
      enabled: false
    },
    chart: {
      zoomType: 'x',
      events: {
        load: () => {
          styleLegendItems();
        },
        redraw: styleLegendItems
      },
      animation: enableAnimation,
      resetZoomButton: {
        theme: {
          style: {
            display: 'none'
          }
        }
      }
    },
    noData: {
      style: {
        display: 'none'
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true,
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      squareSymbol: false
    }
  };
}

// Useful for merging highcharts options with custom series. Always prefer the later defined
// series over the former.
export function arrayMergerHighcharts(objValue: unknown, srcValue: unknown) {
  if (_.isArray(objValue) || _.isArray(srcValue)) {
    return srcValue ?? objValue;
  }
}

export function fitPointsInView<T>(points: T[], width: number) {
  // No need to pass more points than we can possibly show to Highcharts
  if (points == null || width == null || points.length <= width) {
    return points;
  }

  const maxPoints = width * 2;
  const step = Math.ceil(points.length / maxPoints);
  const ret: T[] = [];

  for (let i = 0; i < points.length - 1; i += step) {
    ret.push(points[i]);
  }

  // Always include latest value
  ret.push(points[points.length - 1]);

  return ret;
}

export type ChartZoomSettings = {
  autoUpdate: boolean;
  includeForecastedValues: boolean;
};
