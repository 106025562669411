import {
  QueryClient,
  useQueries,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import APIGen, {
  GetNodesByIdResponseModelV2,
  NodeV2ResponseModel,
  NodeParentInformationResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import _ from 'lodash';
import { useContext, useEffect, useMemo } from 'react';

const queryOptions = {
  staleTime: 1000 * 60 * 5
};

export function getPartialNodeResponse(
  node: NodeV2ResponseModel,
  response: GetNodesByIdResponseModelV2
) {
  const newParents: NodeParentInformationResponseModel[] = [];
  let curParentId = node.parentId;

  while (curParentId != null) {
    const parent = response.parents.find(
      (otherParent) => otherParent.nodeId === curParentId
    );
    if (parent) {
      newParents.push(parent);
      curParentId = parent.parentId;
    } else {
      curParentId = null;
    }
  }

  return {
    nodes: [node],
    parents: newParents
  };
}

export function updateNodeCacheForGetNodesByIds(
  result: GetNodesByIdResponseModelV2,
  contextSettings: ApiContextSettings,
  queryClient: QueryClient
) {
  if (result) {
    result.nodes.forEach((node) => {
      queryClient.setQueryData(
        APIGen.NodesV2.getNodesByIds.path(contextSettings, {
          nodeIds: [node.nodeId]
        }),
        getPartialNodeResponse(node, result)
      );
    });
  }
}

export function useNodeCacheUpdater(result: GetNodesByIdResponseModelV2) {
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  useEffect(() => {
    updateNodeCacheForGetNodesByIds(result, contextSettings, queryClient);
  }, [contextSettings, queryClient, result]);
}

export const BatchedGetNodesQueryKey = 'batchedGetNodes';

export function useNodesEx(nodeIds: string[]) {
  const { tenantId, contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const uniqueNodeIds = useMemo(() => _.uniq(nodeIds), [nodeIds]);

  const nodesWithoutCache = uniqueNodeIds.filter(
    (nodeId) =>
      !queryClient.getQueryData([
        APIGen.NodesV2.getNodesByIds.path(contextSettings, {
          nodeIds: [nodeId]
        })
      ])
  );

  const nodeQuery = useQuery({
    queryKey: [BatchedGetNodesQueryKey, tenantId, ...nodesWithoutCache],
    queryFn: ({ signal }) =>
      APIGen.NodesV2.getNodesByIds.promise(
        contextSettings,
        {
          nodeIds: nodesWithoutCache
        },
        signal
      ),
    ...queryOptions,
    enabled: nodesWithoutCache.length > 0
  });

  useNodeCacheUpdater(nodeQuery.data);

  const result = useMemo(() => {
    const combinedData: GetNodesByIdResponseModelV2 = {
      parents: [],
      nodes: [],
      ...nodeQuery.data
    };
    for (const nodeId of uniqueNodeIds) {
      const cachedData = queryClient.getQueryData<GetNodesByIdResponseModelV2>(
        APIGen.NodesV2.getNodesByIds.path(contextSettings, {
          nodeIds: [nodeId]
        })
      );

      if (cachedData) {
        combinedData.nodes = combinedData.nodes.concat(cachedData.nodes);
        combinedData.parents = combinedData.parents.concat(cachedData.parents);
      }
    }

    combinedData.nodes = _.uniqBy(combinedData.nodes, 'nodeId');
    combinedData.parents = _.uniqBy(combinedData.parents, 'nodeId');

    return combinedData;
  }, [contextSettings, uniqueNodeIds, nodeQuery.data, queryClient]);

  return {
    nodes: result,
    isLoading: nodeQuery.isLoading
  };
}

export function useNodes(nodeIds: string[]) {
  const nodeQuery = APIGen.NodesV2.getNodesByIds.useQuery(
    {
      nodeIds
    },
    {
      ...queryOptions,
      enabled:
        nodeIds != null && nodeIds.length > 0 && !nodeIds.includes(ROOT_NODE_ID)
    }
  );

  useNodeCacheUpdater(nodeQuery.data);

  return {
    nodes: nodeQuery.data?.nodes ?? [],
    parents: nodeQuery.data?.parents ?? [],
    isLoading: nodeQuery.isLoading
  };
}

export function useNode(nodeId: string) {
  const nodeQuery = APIGen.NodesV2.getNodesByIds.useQuery(
    {
      nodeIds: [nodeId]
    },
    {
      ...queryOptions,
      enabled: nodeId != null && nodeId !== ROOT_NODE_ID
    }
  );

  return {
    node: nodeQuery.data?.nodes?.[0] ?? null,
    parents: nodeQuery.data?.parents ?? [],
    isLoading: nodeQuery.isLoading
  };
}

export function useCurrentNode() {
  const nodeId = useCommonSelector((state) => state.general.nodeId);

  const { node, parents, isLoading } = useNode(nodeId);

  return { nodeId, currentNode: node, parents, isLoading };
}

export function useNodeChildren(nodeIds: string[]) {
  const nodeQuery = APIGen.NodesV2.getNodeChildren.useQuery(
    {
      nodeIds
    },
    {
      ...queryOptions,
      enabled: nodeIds != null && nodeIds.length > 0
    }
  );

  const nodeChildren = useMemo(() => {
    return sortByLocaleCompare(nodeQuery.data ?? [], 'name');
  }, [nodeQuery.data]);

  return { nodeChildren, isLoading: nodeQuery.isLoading };
}

export function useCurrentNodeChildren() {
  const nodeId = useCommonSelector((state) => state.general.nodeId);

  const currentNodeIds = useMemo(() => [nodeId], [nodeId]);
  return useNodeChildren(currentNodeIds);
}

export function useNodeEquipmentChildren(nodeIds: string[]) {
  const { contextSettings } = useContext(TenantContext);

  const nodeChildren = useQueries({
    queries: nodeIds.map((nodeId) => ({
      ...queryOptions,
      queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
        nodeIds: [nodeId]
      }),
      queryFn: ({ signal }: { signal?: AbortSignal }) =>
        APIGen.NodesV2.getNodeChildren.promise(
          contextSettings,
          { nodeIds: [nodeId] },
          signal
        )
    }))
  });

  const equipmentChildren = useMemo(() => {
    return _.flatMap(nodeChildren, (query) => {
      return _.filter(query.data, (node) =>
        node.nodeTraitIds.includes(NodeTraitIds.EQUIPMENT)
      );
    });
  }, [nodeChildren]);

  return { equipmentChildren, isLoading: _.some(nodeChildren, 'isLoading') };
}

export function nodeIsSpace(node: Partial<NodeV2ResponseModel>) {
  return node?.nodeTraitIds?.includes(NodeTraitIds.SITE);
}

export function nodeIsBuilding(node: NodeV2ResponseModel) {
  return node?.nodeTraitIds?.includes(NodeTraitIds.BUILDING);
}

export function nodeIsEquipment(node: NodeV2ResponseModel) {
  return node?.nodeTraitIds?.includes(NodeTraitIds.EQUIPMENT);
}

export function nodeIsEnergyManager(node: NodeV2ResponseModel) {
  return node?.nodeTraitIds?.includes(NodeTraitIds.ENERGY_MANAGER);
}

export function resetAllNodeCaches(
  queryClient: QueryClient,
  contextSettings: ApiContextSettings
) {
  queryClient.removeQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings)
  });

  queryClient.removeQueries({
    queryKey:
      APIGen.NodesV2.getNodeWithAncestorsAndSiblings.path(contextSettings)
  });

  queryClient.removeQueries({
    queryKey: [BatchedGetNodesQueryKey]
  });

  queryClient.removeQueries({
    queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
  });
}
