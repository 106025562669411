import React, { useMemo } from 'react';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker.module.css';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';
import APIGen from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import { Y } from 'ecto-common/lib/lang/NodeLocalization';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';

const ToolbarNodeProperties = () => {
  const { nodeId, currentNode } = useCurrentNode();

  const allNodeTraitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const propertiesQuery =
    APIGen.NodesV2.getNodePropertyValuesWithProperties.useQuery(
      {
        nodeId
      },
      {
        enabled: nodeId != null && nodeId !== ROOT_NODE_ID
      }
    );

  const columns = useMemo(() => {
    const ret: React.ReactNode[][] = [];
    const language = getSelectedLanguage();

    const properties = sortByLocaleCompare(propertiesQuery.data ?? [], 'name');

    for (let i = 0; i < properties.length; i += 2) {
      ret.push(
        properties.slice(i, i + 2).map((property) => {
          const title = property.localization?.[language] ?? property.name;
          return (
            <div key={property.propertyId} className={styles.row}>
              <label>{title}:</label>
              <CopyToClipboardTooltip valueToCopy={property.value}>
                {property.value}
              </CopyToClipboardTooltip>
            </div>
          );
        })
      );
    }

    const nodeTraitStrings = sortByLocaleCompare(
      _.compact(
        currentNode?.nodeTraitIds?.map((traitId) => {
          const trait = allNodeTraitsQuery.data?.find((t) => t.id === traitId);

          return trait?.name;
        })
      ),
      _.identity
    );

    const nodeTraitInfo =
      nodeTraitStrings.length > 0 ? (
        <div className={styles.row} key="traitInfo">
          <label>{Y.nodes.nodetraits}: </label>
          <div className={styles.tagContainer}>
            <TagsGroup tags={nodeTraitStrings} />
          </div>
        </div>
      ) : null;

    let lastColumn = ret[ret.length - 1];

    if (lastColumn == null || lastColumn.length === 2) {
      lastColumn = [nodeTraitInfo];
      ret.push(lastColumn);
    } else {
      lastColumn.push(nodeTraitInfo);
    }

    return ret;
  }, [
    allNodeTraitsQuery.data,
    currentNode?.nodeTraitIds,
    propertiesQuery.data
  ]);

  return (
    <div className={styles.properties}>
      {columns.map((column, idx) => (
        <div key={idx} className={styles.column}>
          {column}
        </div>
      ))}
    </div>
  );
};

export default ToolbarNodeProperties;
