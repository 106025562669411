import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import APIGen, {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  NodeSearchResultsResponseModel,
  PropertyValidationType
} from 'ecto-common/lib/API/APIGen';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import TextInputWithMenu from 'ecto-common/lib/TextInput/TextInputWithMenu';

import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';

type NodeV2ResponseModelWithParent = NodeV2ResponseModel & {
  parent: NodeParentInformationResponseModel;
};

const NodeSearchTable = ({
  onClickNode
}: {
  onClickNode: (node: NodeV2ResponseModel) => void;
}) => {
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();
  const propertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery();

  const propertyOptions = useMemo(() => {
    return _.map(propertiesQuery.data, (property) => {
      return {
        label: property.name,
        value: property.id
      };
    });
  }, [propertiesQuery.data]);

  const options = useMemo(() => {
    return _.map(sortByLocaleCompare(traitsQuery.data, 'name'), (trait) => ({
      value: trait.id,
      label: trait.name
    }));
  }, [traitsQuery.data]);

  const [selectedTraitIdsString, setSelectedTraitIds] = useSearchParamState(
    'traits',
    null
  );

  const selectedTraitIds = useMemo(() => {
    if (selectedTraitIdsString == null) {
      return [];
    }

    return selectedTraitIdsString.split(',');
  }, [selectedTraitIdsString]);

  const [selectedPropertyId, setSelectedPropertyId] = useSearchParamState(
    'property',
    null
  );

  const selectedPropertyOption = propertyOptions.find(
    (option) => selectedPropertyId === option.value
  );

  const selectedTraitOptions = useMemo(() => {
    return options.filter((option) => selectedTraitIds.includes(option.value));
  }, [options, selectedTraitIds]);

  const [searchPhrase, setSearchPhrase] = useSearchParamState(
    'node-search',
    ''
  );

  const [debouncedSearchPhrase, _setDebouncedSearchPhrase] =
    useState(searchPhrase);

  const [propertySearchPhrase, setPropertySearchPhrase] = useSearchParamState(
    'property-search',
    ''
  );

  const [propertySuggestionPhrase, setPropertySuggestionPhrase] =
    useState(propertySearchPhrase);

  const setDebouncedSearchPhrase = useMemo(() => {
    return _.debounce((newSearchPhrase: string) => {
      _setDebouncedSearchPhrase(newSearchPhrase);
    }, 200);
  }, []);

  const debouncedSetPropertySuggestionPhrase = useMemo(() => {
    return _.debounce((newSearchPhrase: string) => {
      setPropertySuggestionPhrase(newSearchPhrase);
    }, 200);
  }, []);

  const nodesQuery = APIGen.NodesV2.searchForNodes.useInfiniteQuery(
    {
      nodeTraitIds: selectedTraitIds,
      nodePropertyIds: selectedPropertyId ? [selectedPropertyId] : [],
      searchPhrase: debouncedSearchPhrase,
      propertySearchPhrase,
      pageSize: 20
    },
    {}
  );

  const nodesResult = useMemo(() => {
    const combinedResult: Omit<
      NodeSearchResultsResponseModel,
      'continuationToken'
    > = {
      nodes: [],
      parents: [],
      propertiesAndValues: []
    };

    for (const page of nodesQuery.data?.pages ?? []) {
      combinedResult.nodes.push(...page.nodes);
      combinedResult.parents.push(...page.parents);
      combinedResult.propertiesAndValues.push(...page.propertiesAndValues);
    }

    return combinedResult;
  }, [nodesQuery.data?.pages]);

  const nodePropertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery({});
  const selectedProperty = nodePropertiesQuery.data?.find(
    (x) => x.id === selectedPropertyId
  );

  const nodeRootPropertySuggestionsQuery =
    APIGen.NodesV2.getNodeValueSuggestions.useQuery(
      {
        nodePropertyId: selectedPropertyId,
        searchPhrase: null,
        numSuggestions: 15
      },
      {
        enabled:
          !!selectedPropertyId &&
          selectedProperty?.validationType !== PropertyValidationType.EnumList
      }
    );

  const nodePropertySuggestionsQuery =
    APIGen.NodesV2.getNodeValueSuggestions.useQuery(
      {
        nodePropertyId: selectedPropertyId,
        searchPhrase: propertySuggestionPhrase,
        numSuggestions: 15
      },
      {
        enabled:
          !!selectedPropertyId &&
          selectedProperty?.validationType !==
            PropertyValidationType.EnumList &&
          !nodeRootPropertySuggestionsQuery.isLoading &&
          nodeRootPropertySuggestionsQuery?.data.hasMore
      }
    );

  const columns = useMemo<
    DataTableColumnProps<NodeV2ResponseModelWithParent>[]
  >(() => {
    return _.compact([
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      },

      selectedPropertyId && {
        dataKey: 'nodeId',
        label: selectedProperty?.name,
        dataFormatter: (nodeId: string) => {
          const propertyValue = nodesResult.propertiesAndValues.find(
            (x) =>
              x.nodeId === nodeId && x.nodePropertyId === selectedPropertyId
          );

          return propertyValue?.value;
        }
      },
      {
        dataKey: 'parent.name',
        label: 'Parent'
      },
      {
        dataKey: 'nodeTraitIds',
        label: 'Traits',
        dataFormatter: (traitIds: string[]) => {
          const traits = sortByLocaleCompare(
            _.compact(
              _.map(traitIds, (traitId) => {
                return _.find(traitsQuery.data, { id: traitId });
              })
            ),
            'name'
          );

          return (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                gap: 1
              }}
            >
              <TagsGroup tags={_.orderBy(traits, 'name').map((t) => t.name)} />
            </div>
          );
        }
      }
    ]);
  }, [
    nodesResult.propertiesAndValues,
    selectedProperty?.name,
    selectedPropertyId,
    traitsQuery.data
  ]);

  const onClickRow = (node: NodeV2ResponseModel) => {
    onClickNode(node);
  };

  const searchTableData: NodeV2ResponseModelWithParent[] = useMemo(() => {
    return _.map(nodesResult.nodes, (node) => {
      return {
        ...node,
        parent: nodesResult.parents.find(
          (parent) => parent.nodeId === node.parentId
        )
      };
    });
  }, [nodesResult.nodes, nodesResult.parents]);

  const searchPhraseOptions = useMemo(() => {
    if (selectedProperty?.validationType === PropertyValidationType.EnumList) {
      return _.map(selectedProperty?.validationData?.enumValues, (value) => ({
        label: value,
        value
      }));
    }

    let collection = nodePropertySuggestionsQuery.data?.suggestions;

    if (!nodeRootPropertySuggestionsQuery.data?.hasMore) {
      collection = nodeRootPropertySuggestionsQuery.data?.suggestions;
    }

    return _.map(collection, (value) => ({
      label: value,
      value
    }));
  }, [
    nodePropertySuggestionsQuery.data?.suggestions,
    nodeRootPropertySuggestionsQuery.data?.hasMore,
    nodeRootPropertySuggestionsQuery.data?.suggestions,
    selectedProperty?.validationData?.enumValues,
    selectedProperty?.validationType
  ]);

  const [propertyInputIndex, setPropertyInputIndex] = useState(-1);
  const [propertyInputFocused, setPropertyInputFocused] = useState(false);

  if (propertyInputIndex > searchPhraseOptions.length - 1) {
    setPropertyInputIndex(searchPhraseOptions.length - 1);
  }

  return (
    <>
      <KeyValueLine>
        <KeyValueGeneric keyText="Filter on node types">
          <Select<GenericSelectOption<string>, true>
            options={options}
            isMulti
            onChange={(data: GenericSelectOption<string>[]) => {
              setSelectedTraitIds(data.map((x) => x.value).join(','));
            }}
            value={selectedTraitOptions}
          />
        </KeyValueGeneric>
      </KeyValueLine>
      <KeyValueLine>
        <KeyValueGeneric keyText="Filter node property">
          <Select<GenericSelectOption<string>, false>
            options={propertyOptions}
            placeholder="Select node property"
            onChange={(data: GenericSelectOption<string>) => {
              setSelectedPropertyId(data?.value);
              setPropertySuggestionPhrase(null);
              setPropertySearchPhrase(null);
            }}
            value={selectedPropertyOption}
            isClearable
          />
        </KeyValueGeneric>

        <KeyValueGeneric keyText="Search property value">
          <TextInputWithMenu
            placeholder="Search property value"
            highlightText={propertySuggestionPhrase}
            onSelectMenuOption={(option) => {
              setPropertySuggestionPhrase(option.value);
              setPropertySearchPhrase(option.value);
            }}
            clearButton
            onClear={() => {
              setPropertySuggestionPhrase(null);
              setPropertySearchPhrase(null);
            }}
            onArrowDownKeyPressed={() => {
              setPropertyInputIndex((oldIndex) => {
                let newIndex = oldIndex + 1;
                if (newIndex >= searchPhraseOptions.length) {
                  newIndex = 0;
                }
                return Math.min(newIndex, searchPhraseOptions.length - 1);
              });
            }}
            onArrowUpKeyPressed={() => {
              setPropertyInputIndex((oldIndex) => {
                let newIndex = oldIndex - 1;
                if (newIndex < 0) {
                  newIndex = searchPhraseOptions.length - 1;
                }
                return newIndex;
              });
            }}
            onEnterKeyPressed={() => {
              if (propertyInputIndex >= 0) {
                setPropertySuggestionPhrase(
                  searchPhraseOptions[propertyInputIndex].value
                );
                setPropertySearchPhrase(
                  searchPhraseOptions[propertyInputIndex].value
                );
              } else {
                setPropertySearchPhrase(propertySuggestionPhrase);
              }
            }}
            onFocus={() => {
              setPropertyInputFocused(true);
            }}
            onFocusLost={() => {
              setPropertyInputIndex(-1);
              setPropertyInputFocused(false);
            }}
            value={propertySuggestionPhrase}
            selectedIndex={propertyInputIndex}
            showMenu={searchPhraseOptions.length > 0 && propertyInputFocused}
            onChange={(e) =>
              debouncedSetPropertySuggestionPhrase(e.target.value)
            }
            menuOptions={searchPhraseOptions}
          />
        </KeyValueGeneric>
      </KeyValueLine>
      <KeyValueLine>
        <KeyValueInput
          keyText="Search nodes"
          placeholder="Search nodes"
          value={searchPhrase}
          onChange={(e) => {
            setSearchPhrase(e.target.value);
            setDebouncedSearchPhrase(e.target.value);
          }}
        />
      </KeyValueLine>
      <DataTable
        columns={columns}
        onClickRow={onClickRow}
        data={searchTableData}
        isLoading={nodesQuery.isLoading}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={nodesQuery.isFetchingNextPage}
        hasNextPage={nodesQuery.hasNextPage}
        fetchNextPage={nodesQuery.fetchNextPage}
      />
    </>
  );
};

export default React.memo(NodeSearchTable);
