import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';

import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';

import styles from './SelectEquipment.module.css';
import APIGen, { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import LocationSearch from 'ecto-common/lib/Page/LocationSearch';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import useNodeTree from 'ecto-common/lib/LocationTreeView/useNodeTree';

interface SelectEquipmentProps {
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  selectFromCurrentNodeOnly?: boolean;
  multiSelect?: boolean;
  embedInDialog?: boolean;
}

const SelectEquipment = ({
  selectedIds,
  setSelectedIds,
  selectFromCurrentNodeOnly,
  multiSelect = false,
  embedInDialog = false
}: SelectEquipmentProps) => {
  const scrollerRef = useRef(null);

  const [filterText, setFilterText] = useState('');

  const updateSearch = useCallback((value: string) => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTop = 0;
    }

    setFilterText(value);
  }, []);

  const onSelectedChanged = useCallback(
    (itemId: string, isSelected: boolean) => {
      setSelectedIds((oldSelectedIds) => {
        if (multiSelect) {
          return isSelected
            ? [...oldSelectedIds, itemId]
            : oldSelectedIds.filter((x) => x !== itemId);
        }

        return [itemId];
      });

      setFilterText('');
    },
    [multiSelect, setSelectedIds]
  );

  const hasSearchTerm = !isNullOrWhitespace(filterText);

  const searchQuery = APIGen.NodesV2.searchForNodes.useQuery(
    {
      nodeTraitIds: [],
      nodePropertyIds: [],
      searchPhrase: filterText,
      pageSize: 20
    },
    {
      enabled: hasSearchTerm
    }
  );

  const {
    nodes,
    renderRowSideIcons,
    isLoadingHierarchy,
    nodeHasChildren,
    onExpandedStateChange,
    expanded,
    setExpanded
  } = useNodeTree(_.head(selectedIds), _.noop);

  const allNodes: NodeV2ResponseModel[] = useMemo(() => {
    if (selectFromCurrentNodeOnly) {
      return _.compact([
        nodes.find(
          (x) =>
            selectedIds.includes(x.nodeId) || selectedIds.includes(x.parentId)
        )
      ]);
    }

    return nodes;
  }, [nodes, selectFromCurrentNodeOnly, selectedIds]);

  const onNodeSelected = useCallback(
    (itemId: string) => {
      setSelectedIds([itemId]);
    },
    [setSelectedIds]
  );

  return (
    <>
      <div className={styles.filterBox}>
        <SearchInput onChange={updateSearch} initialValue={filterText} />
      </div>
      <div
        style={{ flexGrow: 1, overflowY: 'auto' }}
        data-parent-scroller={!embedInDialog ? 'true' : undefined}
        ref={scrollerRef}
      >
        {hasSearchTerm && (
          <LocationSearch
            isLoading={searchQuery.isLoading}
            searchResults={searchQuery.data}
            onNodeSelected={onNodeSelected}
          />
        )}
        {!isLoadingHierarchy && !hasSearchTerm && (
          <LocationTreeView
            nodeList={allNodes}
            onChangeSelectedState={onSelectedChanged}
            selectedIds={selectedIds}
            renderRowSideIcons={renderRowSideIcons}
            expanded={selectFromCurrentNodeOnly ? null : expanded}
            setExpanded={setExpanded}
            onExpandedStateChange={onExpandedStateChange}
            nodeHasChildren={nodeHasChildren}
            focusedId={_.head(selectedIds)}
            multiSelect={multiSelect}
          />
        )}
      </div>
    </>
  );
};

export default SelectEquipment;
