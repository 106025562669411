export const Y = {
  admin: {
    editbuilding: {
      invalidproperties: {
        title: 'Invalid property values',
        text: 'Please correct the invalid property values before saving.'
      }
    },
    editlocation: {
      addnewrootnode: 'Add root node',
      selectformats: {
        dashboards: '{0} dashboard(s)',
        notifications: '{0} notification(s)',
        files: '{0} file(s)'
      },
      editmapcoordinate: {
        title: 'Edit node location'
      },
      addfromtemplate: {
        title: 'Add equipment from template'
      },
      section: {
        integrations: 'Integrations',
        nodedetails: 'Details',
        meteorology: 'Weather point'
      },
      fields: {
        notifications: 'Notifications',
        files: 'Files',
        parent: 'Parent node',
        dashboards: 'Dashboards',
        mapcoordinate: 'Location',
        description: 'Description',
        processmaps: 'Process maps'
      }
    },
    createlocation: {
      addnode: 'Add node',
      nodetype: 'Node type'
    },
    deletelocation: {
      text: 'Deleting this node removes all equipment and related signals.',
      title: 'Delete node'
    }
  },
  nodes: {
    advancedsearch: 'Advanced Search',
    locked: 'Locked',
    global: 'Global',
    nodetraits: 'Traits',
    nodeproperties: 'Properties'
  },
  traits: {
    edittrait: 'Edit trait',
    addtrait: 'Add trait'
  },
  properties: {
    name: 'Property name',
    enumvalues: 'Valid values',
    regex: 'Regular expression',
    validationtype: 'Validation type',
    editproperty: 'Edit property',
    addproperty: 'Add property',
    localizationformat: 'Localization {0}',
    validationtypes: {
      none: 'None',
      regex: 'Regular expression',
      enumlist: 'Valid values'
    }
  }
};
