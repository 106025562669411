import React, {
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useState
} from 'react';
import _ from 'lodash';
import { LocationTreeViewNodeWithChildren } from './LocationTreeViewRow';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNavigate } from 'react-router-dom';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import APIGen, {
  NodeParentInformationResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import dimensions from 'ecto-common/lib/styles/dimensions';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useStore } from 'zustand';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { ROOT_NODE_ID } from '../constants/index';
import { nodeTreeStore } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

export function updateGetNodeCache(
  nodes: NodeV2ResponseModel[],
  allNodes: NodeV2ResponseModel[],
  contextSettings: ApiContextSettings,
  queryClient: QueryClient
) {
  for (const node of nodes) {
    const queryKey = APIGen.NodesV2.getNodesByIds.path(contextSettings, {
      nodeIds: [node.nodeId]
    });
    if (!queryClient.getQueryData(queryKey)) {
      let parentInfos: NodeParentInformationResponseModel[] = [];
      let currentParentId = node.parentId;
      let updateData = true;

      while (currentParentId != null) {
        const parent = allNodes.find(
          (otherNode) => otherNode.nodeId === currentParentId
        );
        if (parent != null) {
          parentInfos = [
            ...parentInfos,
            {
              nodeId: parent.nodeId,
              name: parent.name,
              parentId: parent.parentId
            }
          ];

          currentParentId = parent.parentId;
        } else {
          console.error('Unable to find parent for node', node.nodeId);
          updateData = false;
          break;
        }
      }

      if (updateData) {
        queryClient.setQueryData(queryKey, {
          nodes: [node],
          parents: parentInfos
        });
      }
    }
  }
}

function updateGetNodeHierachyCache(
  nodes: NodeV2ResponseModel[],
  nodeId: string,
  contextSettings: ApiContextSettings,
  queryClient: QueryClient
) {
  const currentNode = nodes.find((node) => node.nodeId === nodeId);

  let currentParentId = currentNode?.parentId;

  let currentNodes = _.cloneDeep(nodes);

  while (currentParentId) {
    const nodesWithSameParent = currentNodes.filter(
      (node) => node.parentId === currentParentId
    );

    for (const node of nodesWithSameParent) {
      const queryKey = APIGen.NodesV2.getNodeWithAncestorsAndSiblings.path(
        contextSettings,
        {
          nodeId: node.nodeId
        }
      );

      if (!queryClient.getQueryData(queryKey)) {
        queryClient.setQueryData(queryKey, currentNodes);
      }
    }

    const parent = currentNodes.find((node) => node.nodeId === currentParentId);

    currentParentId = parent?.parentId;

    currentNodes = currentNodes.filter((x) => !nodesWithSameParent.includes(x));
  }
}

const queryOptions = {
  staleTime: 1000 * 60 * 5
};

export default function useNodeTree(
  nodeId: string,
  onNavigateToRootNode: (rootNode: NodeV2ResponseModel) => void,
  filterNodes?: (node: NodeV2ResponseModel) => boolean
) {
  const allNodes = useStore(nodeTreeStore, (store) => store.allNodes);
  const addNodes = useStore(nodeTreeStore, (store) => store.addNodes);
  const emptyChildren = useStore(nodeTreeStore, (store) => store.emptyChildren);

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { tenantId } = useContext(TenantContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const initialQuery = APIGen.NodesV2.getNodeWithAncestorsAndSiblings.useQuery(
    {
      nodeId
    },
    {
      ...queryOptions,
      enabled:
        nodeId != null &&
        nodeId !== ROOT_NODE_ID &&
        allNodes.find((x) => x.nodeId === nodeId) == null
    }
  );

  // Used when the cache is cleared completely (for instance when parent is changed)
  if (allNodes.length === 0 && !_.isEmpty(expanded)) {
    setExpanded({});
  }

  useEffect(() => {
    if (nodeId === ROOT_NODE_ID && rootLevelNodes.length > 0) {
      addNodes(rootLevelNodes);
    }
  }, [addNodes, nodeId, rootLevelNodes]);

  useEffect(() => {
    if (initialQuery.data) {
      if (initialQuery.data.length === 0) {
        const rootLevelNode = rootLevelNodes[0];
        if (rootLevelNode) {
          onNavigateToRootNode?.(rootLevelNode);
        }
      }

      addNodes(initialQuery.data);
    }
  }, [
    addNodes,
    contextSettings,
    filterNodes,
    initialQuery.data,
    navigate,
    nodeId,
    onNavigateToRootNode,
    rootLevelNodes,
    tenantId
  ]);

  useEffect(() => {
    if (initialQuery.data) {
      updateGetNodeCache(
        initialQuery.data ?? [],
        allNodes.concat(initialQuery.data ?? []),
        contextSettings,
        queryClient
      );
      updateGetNodeHierachyCache(
        initialQuery.data ?? [],
        nodeId,
        contextSettings,
        queryClient
      );
    }
  }, [allNodes, contextSettings, initialQuery.data, nodeId, queryClient]);

  const loadChildrenMutation = APIGen.NodesV2.getNodeChildren.useMutation({
    onSuccess: (data, args) => {
      updateGetNodeCache(data, allNodes, contextSettings, queryClient);

      setLoadingState((oldState) => {
        return {
          ...oldState,
          [_.head(args.nodeIds)]: false
        };
      });

      addNodes(data);
    }
  });

  const childrenAreEmpty = useCallback(
    (parentId: string) => {
      return emptyChildren[parentId];
    },
    [emptyChildren]
  );

  const hasLoadedChildren = useCallback(
    (parentId: string) => {
      return allNodes.some((node) => node.parentId === parentId);
    },
    [allNodes]
  );

  const loadChildren = useCallback(
    (newNodeId: string) => {
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [newNodeId]: true
        };
      });

      loadChildrenMutation.mutateAsync({
        nodeIds: [newNodeId]
      });
    },
    [loadChildrenMutation]
  );

  // Assume nodes has children until we've loaded them and know for sure
  const nodeHasChildren = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      return !childrenAreEmpty(node.nodeId);
    },
    [childrenAreEmpty]
  );

  const onExpandedStateChange = useCallback(
    (expandedNodeId: string, nodeIsExpanded: boolean) => {
      if (!hasLoadedChildren(expandedNodeId)) {
        loadChildren(expandedNodeId);
      }

      setExpanded((oldExpanded) => {
        return {
          ...oldExpanded,
          [expandedNodeId]: nodeIsExpanded
        };
      });
    },
    [hasLoadedChildren, loadChildren]
  );

  const renderRowSideIcons = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      let loadingIcon: React.ReactNode = null;

      if (loadingState[node.nodeId]) {
        loadingIcon = (
          <div
            style={{
              flexShrink: 0,
              marginLeft: dimensions.smallMargin,
              marginRight: 10
            }}
          >
            <Spinner
              size={SpinnerSize.TREE_VIEW}
              color={node.nodeId === nodeId ? 'white' : 'green'}
            />
          </div>
        );
      }

      return (
        <>
          <div style={{ flex: 1 }} />
          <div
            style={{
              minWidth: 30,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {loadingIcon}
          </div>
        </>
      );
    },
    [loadingState, nodeId]
  );

  const filteredNodes = useMemo(() => {
    if (filterNodes) {
      return sortByLocaleCompare(allNodes.filter(filterNodes), 'name');
    }

    return sortByLocaleCompare(allNodes, 'name');
  }, [allNodes, filterNodes]);

  return {
    nodes: filteredNodes,
    isLoadingHierarchy: initialQuery.isLoading,
    loadingState,
    nodeHasChildren,
    onExpandedStateChange,
    expanded,
    setExpanded,
    renderRowSideIcons
  };
}
